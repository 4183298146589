.is-bordered {
  border: $border-variable !important;
}

.image img {
  &.is-cover {
    object-fit: cover;
  }

  &.is-contain {
    object-fit: contain;
  }
}

.tag:not(body) {
  font-size: clamp(1rem, 0.956rem + 0.1878vi, 1.125rem); // 16-18
  padding-left: 0.5em;
  padding-right: 0.5em;
  min-width: 2em;

  &.is-small {
    font-size: clamp(0.875rem, 0.8462rem + 0.1282vi, 1rem); // 14-16
  }

  .icon:first-child:not(:last-child) {
    margin-left: 0;
  }
}

.tags {
  &.has-addons {
    padding: 0 3px 3px 0;

    .tag {
      font-weight: $weight-bold;
      border: $border-variable;
      margin: 0 -3px -3px 0;
    }
  }
}

.icon {
  &,
  .button & {
    height: 1em;
    width: 1em;
  }

  svg {
    height: 1em;
    width: 1em;
  }
}

#notification-container {
  &.is-bottom {
    position: fixed;
    z-index: 5;
    right: 12px;
    left: 12px;
    bottom: 12px;

    @include tablet {
      right: auto;
      left: 32px;
      bottom: 32px;
    }
  }

  .notification {
    max-width: 600px;
  }
}

.notification {
  border: $border-variable;
  font-size: $size-8;
  text-align: left;
  display: inline-flex;
  align-items: flex-start;

  .icon {
    font-size: 1.5em;
    margin-right: 1rem;
  }
}

.form-container {
  font-size: $size-8;

  .input {
    font-weight: $weight-medium;
  }

  .is-field-error {
    display: inline-block;
    background: $danger;
    padding: 0.25rem 0.5rem;
    margin-top: 0.5rem;
    // border: $border-variable;
  }

  .field {
    &.has-error {
      .input {
        background: $danger;
      }
    }
  }

  button[type='submit'] {
    margin: 32px 0;
  }
}

.block-embed_block {
  .image {
    background: $black;
  }

  .image > * {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .container.is-small {
    border: 32px solid $black;
  }

  .section {
    @include mobile {
      padding-left: 0;
      padding-right: 0;

      .container.is-small {
        width: 100%;
        border: 16px solid $black;
      }
    }
  }
}

.has-hover-icon-rotate {
  .icon-background {
    transform: rotate(0deg);
    transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  &:hover {
    .icon-background {
      transform: rotate(45deg);
    }
  }
}

#is-mobile-sticky {
  padding: 12px 16px;
  border-top: $border-variable;
  background: #f4f1ec80;
  backdrop-filter: blur(60px);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  // padding-right: 120px;
  transform: translateY(+100%);
  transition: transform 0.5s;

  &.is-active {
    transform: translateY(0);
  }

  // @TODO Fix this better
  & + section:has(> .container.is-bordered) {
    margin-top: 24px;
  }

  @include tablet {
    display: none;
  }
}
