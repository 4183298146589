.modal {
  .modal-content {
    background: $grey;
    display: flex;
    flex-direction: column;

    header {
      padding: 10px 20px;
      border-bottom: $border-variable;
      display: flex;
      align-items: center;
      font-weight: $weight-bold;
    }

    main {
      flex: 1;
      overflow: auto;
      padding: 16px 20px;
    }

    footer {
      background: $secondary;
      border-top: $border-variable;
      padding: 16px 16px 48px 16px;
    }
  }

  &.is-right {
    .modal-content {
      overflow: hidden;
      margin-right: 0;
      height: 100vh;

      @include tablet {
        border-left: $border-variable;
      }
    }
  }

  &.is-left {
    .modal-content {
      overflow: hidden;
      margin-left: 0;
      height: 100vh;

      @include tablet {
        border-right: $border-variable;
      }
    }
  }
}
