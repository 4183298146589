.button {
  font-weight: $weight-bold;
  font-size: clamp(1.1875rem, 1.1215rem + 0.2817vi, 1.375rem); // 19-22
  height: clamp(3rem, 2.8846rem + 0.5128vi, 3.5rem); //48-56
  max-width: 100%;

  .has-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &.is-square {
    padding: 0;
    aspect-ratio: 1;
  }

  .icon {
    &:first-child:not(:last-child) {
      margin-left: 0;
      margin-right: 8px;
    }

    &:last-child:not(:first-child) {
      margin-right: 0;
      margin-left: 8px;
    }
  }

  &.is-header {
    font-size: clamp(1rem, 0.868rem + 0.5634vi, 1.375rem); //16-22
  }

  &.is-special {
    font-size: clamp(1.5rem, 1.2359rem + 1.1268vi, 2.25rem); //24-36
    height: clamp(3.625rem, 3.3365rem + 1.2821vi, 4.875rem);
  }

  &.is-medium {
    @extend .is-size-7;
  }

  &.is-small {
    @extend .is-size-8;
    border-radius: 0;
    height: 38px;
  }

  &.is-fav {
    width: clamp(2.5rem, 2.3846rem + 0.5128vi, 3rem);
    height: clamp(2.5rem, 2.3846rem + 0.5128vi, 3rem);

    .icon {
      pointer-events: none;
    }

    &.is-black {
      .icon svg:first-child {
        display: none;
      }

      &:hover {
        backdrop-filter: blur(60px);
        background-color: rgba(0, 0, 0, 0.35);
      }
    }

    &.is-primary {
      .icon svg:last-child {
        display: none;
      }
    }
  }

  &.is-document {
    height: auto;
    white-space: wrap;
  }

  &.is-dashed {
    border-style: dashed;
  }

  &[data-scroll] * {
    pointer-events: none;
  }
}

.buttons {
  @include tablet {
    &.is-centered-tablet {
      justify-content: center;
    }
  }
}
