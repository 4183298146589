$loading-background: rgba(0, 0, 0, 0.5);
$checkbox-border-color: $border;
$checkbox-border-radius: 0;
$checkbox-border-width: 3px;
$checkbox-size: 2em;
$checkbox-checkmark-color: $black;

@import 'buefy/src/scss/utils/_all';

@import 'buefy/src/scss/components/_checkbox';
@import 'buefy/src/scss/components/_loading';

.b-checkbox.checkbox {
  font-weight: $weight-bold;

  &:hover input[type='checkbox']:not(:checked) + .check {
    background: $primary;
  }

  &:hover input[type='checkbox']:not(:disabled) + .check {
    border-color: $black;
  }

  &:not(.button) {
    margin-right: 0;
  }

  & input[type='checkbox']:checked + .check {
    border-color: $black;
    transition: none;
    background-size: 70%;
  }

  .control-label {
    display: flex;
    flex: 1;
    padding-right: 12px;
  }

  &:has(input[type='checkbox']:checked) {
    background: $primary;
  }
}
