.is-rotating {
  animation: rotate 20s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-100% - 0.5em));
  }
}

@keyframes scrollreverse {
  0% {
    transform: translateX(calc(-100% - 0.5em));
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(90deg);
  }
}

@keyframes pattern-a1 {
  0% {
    transform: translateY(-40px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes pattern-a2 {
  0% {
    transform: translateX(-42px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes pattern-a3 {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(-30px);
  }
}

@keyframes pattern-a4 {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-30px);
  }
}

@media (prefers-reduced-motion: reduce) {
  .marquee-body {
    animation-play-state: paused;
  }
}
