#media-grid {
  .container {
    padding: 20px 0;

    @include tablet {
      padding: 40px 0px;
    }

    @include desktop {
      padding: 60px 0;
    }
  }

  .content a {
    color: $white;
    text-decoration: underline;
  }

  .is-overlay {
    .icon {
      display: block;
      position: absolute;
      bottom: 20px;
      right: 15px;

      svg rect {
        opacity: 0.7;
        fill: $black;
      }
    }
  }

  .columns {
    flex: 1;
  }

  .image {
    background: $black;
    min-height: 100%;

    img {
      transition: opacity 3s cubic-bezier(0.4, 0, 0.25, 1);

      &.is-transparent {
        pointer-events: none;
        opacity: 0;
        transition: opacity 2s cubic-bezier(0.4, 0, 0.25, 1);
      }
    }
  }
}
