.has-pattern-1 {
  background-image: linear-gradient(135deg, currentColor 25%, transparent 25%),
    linear-gradient(225deg, currentColor 25%, transparent 25%),
    linear-gradient(45deg, currentColor 25%, transparent 25%),
    linear-gradient(315deg, currentColor 25%, transparent 25%);
  background-position: 20px 0, 20px 0, 0 0, 0 0;
  background-size: 40px 40px;
  background-repeat: repeat;
}

.has-pattern-2 {
  background-image: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 15px,
    currentColor 15px,
    currentColor 30px
  );
}

.has-pattern-3 {
  background-image: repeating-linear-gradient(
    90deg,
    currentColor,
    currentColor 15px,
    transparent 15px,
    transparent 30px
  );
}

.has-pattern-4 {
  background-image: repeating-linear-gradient(
    180deg,
    transparent,
    transparent 15px,
    currentColor 15px,
    currentColor 30px
  );
}

.has-pattern-5 {
  background-image: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 15px,
    currentColor 15px,
    currentColor 30px
  );
}

.pattern-separator {
  height: 120px;
}
