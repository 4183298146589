//////////////////////////////////////// COLOURS

$white: #ffffff;
$black: #2a2221;

$grey-darker: #4c4e55;
$grey-dark: #c3c3c3b2;
$grey: #f4f1ec;
$grey-light: #ececec;
$grey-lighter: #f7f7f7;

$light: $grey;
$light-invert: $black;
// $dark: $grey-darker;

$primary: #ffe500;
$primary-invert: $black;
$secondary: #98ebff;
$secondary-dark: #0961c8;

$red: #fc1414;
// $danger: $red;
$danger: #ffd6d6;
$danger-invert: $black;

$background: $secondary;
$border: $black;
$border-variable: 3px solid $border;

$custom-colors: (
  'secondary': $secondary,
);

//////////////////////////////////////// BULMA OVERWRITE

// body
$family-primary: 'neue-haas-grotesk-text', sans-serif;

$body-background-color: $background;
$body-color: $black;
$body-size: 16px;
$body-weight: 500;

$white-invert: $black;

$radius-small: 0;
$radius: 20px;
$radius-medium: 45px;
$radius-rounded: 360px;

// navbar
$navbar-height: 7.75rem;
$navbar-item-img-max-height: 7.75rem;
$navbar-background-color: transparent;
$navbar-burger-color: $white;

// typo + sizes
$size-1: clamp(4rem, 1rem + 13.3333vi, 15rem); // 64-240px
$size-2: clamp(3rem, 1.5rem + 6.6667vi, 7.5rem); // 48-120px
$size-3: clamp(2.5rem, 1.1304rem + 6.087vi, 6rem); // 40-96px
$size-4: clamp(2rem, 1.0217rem + 4.3478vi, 4.5rem); // 32-72px
$size-5: clamp(1.625rem, 1.3789rem + 1.0938vi, 2.5rem); // 26-40px
$size-6: clamp(1.3125rem, 1.1902rem + 0.5435vi, 1.625rem); // 21-26px
$size-7: clamp(1.1875rem, 1.0652rem + 0.5435vi, 1.5rem); // 19-24px
$size-8: 1.125rem; // 18px
$size-9: 1rem; // 16px
$size-10: 0.875rem; // 14px

$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 $size-7 $size-8 $size-9
  $size-10;

$size-small: $size-10;
$size-normal: $size-9;
$size-medium: $size-8;
$size-large: $size-7;

$lh-1: 1.1;
$lh-2: 1.1;
$lh-3: 1.15;
$lh-4: 1.15;
$lh-5: 1.2;
$lh-6: 1.2;
$lh-7: 1.35;
$lh-8: 1.2;
$lh-9: 1.2;
$lh-10: 1.4;
$line-heights: $lh-1 $lh-2 $lh-3 $lh-4 $lh-5 $lh-6 $lh-7 $lh-8 $lh-9 $lh-10;

$ls-1: clamp(-0.3125rem, 0.0057rem + -0.303vi, -0.0625rem);
$ls-2: clamp(-0.125rem, 0.0054rem + -0.163vi, -0.0312rem);
$ls-3: clamp(-0.125rem, 0.0054rem + -0.163vi, -0.0312rem);
$ls-4: clamp(-0.0625rem, -0.019rem + -0.0543vi, -0.0312rem);
$ls-5: clamp(-0.0625rem, -0.019rem + -0.0543vi, -0.0312rem);
$ls-6: normal;
$ls-7: normal;
$ls-8: normal;
$ls-9: normal;
$ls-10: normal;
$letter-spacings: $ls-1 $ls-2 $ls-3 $ls-4 $ls-5 $ls-6 $ls-7 $ls-8 $ls-9 $ls-10;

// spacing + margins
$sc-1: clamp(5rem, 4.3182rem + 3.0303vi, 7.5rem); //80-120
$sc-1_2: clamp(2rem, 1.3077rem + 3.0769vi, 5rem); //32-80
$sc-2: clamp(2rem, 1.6667rem + 1.4815vi, 3rem); //32-48
$sc-3: clamp(2rem, 1.8333rem + 0.7407vi, 2.5rem); //32-40
$sc-4: clamp(1rem, 1.1667rem + 1.4815vi, 2.5rem); //16-40
$sc-5: clamp(1.5rem, 1.3333rem + 0.7407vi, 2rem); //24-32
$sc-6: clamp(0.5rem, 0.3333rem + 0.7407vi, 1rem); //8-16
// $spacing-clamps: $sc-1 $sc-2 $sc-3 $sc-4 $sc-5 $sc-6;

$spacing-values: (
  '0': 0,
  '1': 0.25rem,
  '2': 0.5rem,
  '3': 0.75rem,
  '4': 1rem,
  '5': 24px,
  '6': 40px,
  '7': 48px,
  '8': 64px,
  '9': 80px,
  '10': $sc-1,
  'auto': auto,
);

// layout
$gap: 30px;
$column-gap: 20px;
$block-spacing: 20px;
$section-padding: $sc-4;
$section-padding-medium: $sc-2 0 80px;
$section-padding-large: 80px 0 120px;
$footer-padding: 32px 0;

// component colors
$text: $black;
$text-strong: $black;
$strong-color: $black;
$tag-color: $black;
$link: $black;
$link-hover: $black;
$link-active-border: $black;
$link-focus-border: $primary;
$title-color: inherit;
$footer-background-color: $background;
$content-heading-color: $black;
$title-weight: normal;

// form
$input-focus-box-shadow-size: 0;
$input-background-color: $grey;
$input-hover-color: $black;
$input-radius: 0;
$input-hover-border-color: $black;
$input-focus-border-color: $black;
$input-icon-color: $black;
$input-icon-active-color: $black;
$input-shadow: none;
$label-color: $black;
$label-weight: 500;

// modals
$modal-content-width: 450px;
$modal-content-margin-mobile: 0;
$modal-content-spacing-mobile: 0px;
$modal-content-spacing-tablet: 0px;
// $modal-card-body-padding: 20px;
// $modal-close-dimensions: 40px;
// $modal-z: 40;

// content
$content-blockquote-background-color: $white;
$content-blockquote-border-left: 0;
$content-blockquote-padding: 20px 0;
$tag-radius: 0;
$notification-radius: 0;
$notification-padding-ltr: 12px 16px;

// aside menu
$menu-list-link-padding: 8px 24px;
// $menu-item-hover-color: $primary;
// $menu-item-hover-background-color: $white;
// $menu-item-active-color: $primary;
// $menu-item-active-background-color: transparent;
// $menu-item-hover-background-color: transparent;

// controls
$control-border-width: 3px;
$control-radius: 0;
$control-height: 56px;
$control-padding-horizontal: 1rem;
// $control-line-height: 1.2;

$button-padding-horizontal: 16px;
$button-padding-vertical: 14px;
$button-color: $black;
$button-hover-color: $black;
$button-border-color: $black;
$button-hover-border-color: $black;
$button-focus-border-color: $black;
// $button-active-border-color: $black;
// $button-focus-box-shadow-size: 0;

// $dropdown-content-radius: $radius-control;
// $dropdown-content-padding-top: 22px;
// $dropdown-content-padding-bottom: 22px;
// $dropdown-content-background-color: $white;
// $dropdown-content-shadow: none;
// $dropdown-item-color: $black;
// $dropdown-item-hover-color: $primary;
// $dropdown-item-hover-background-color: transparent;
// $dropdown-item-active-color: $primary;
// $dropdown-item-active-background-color: transparent;

// screen
$tablet: 768px;
$desktop: 1080px;
$widescreen: 1440px;
$fullhd: 1620px;
// $navbar-breakpoint: $tablet;
