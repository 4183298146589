footer.footer {
  .container {
    border-top: 1px dashed $border;
    padding: 32px 0;

    .column {
      align-items: center;

      .image {
        svg {
          display: block;
        }
      }

      img {
        height: 50px;
      }

      @include mobile {
        display: flex;
        justify-content: center;

        &.has-category {
          text-align: center;
        }
      }

      @include tablet {
        &.has-no-category {
          img {
            margin-top: 44px;
          }
        }
      }
    }

    .button.is-rounded {
      height: 45px;
    }

    .columns {
      &.is-vcentered {
        justify-content: space-between;
        .column {
          flex: none;
        }
      }
    }
  }
}
