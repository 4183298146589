.carousel {
  .oh-caption {
    margin-top: 16px;

    .buttons {
      flex-wrap: nowrap;
    }
  }

  &.is-default {
    .carousel-container {
      aspect-ratio: 4/3;

      @include tablet {
        aspect-ratio: 16/9;
      }

      .flickity-viewport {
        position: absolute;
        width: 100%;

        .carousel-item,
        .image {
          height: 100%;
          width: 100%;
        }

        .image {
          img {
            height: 100%;
          }
        }

        .is-caption {
          min-height: 28px;
          position: absolute;
          z-index: 1;
          bottom: 0;
          left: 0;
          width: 100%;
          background: $grey;
        }
      }
    }

    .button.is-counter {
      display: none;
      width: 80px;
      pointer-events: none;
      border: 0;
      background: transparent;
    }

    &.has-captions {
      .button.is-counter {
        display: inline-flex;
      }
    }
  }

  &.is-boxed {
    .carousel-container {
      @include tablet {
        border: $border-variable;
        border-style: dashed;
        border-top-width: 0;
        border-bottom-width: 0;
        padding: 12px 0;
      }

      .carousel-item {
        width: 90%;
        padding: 0 12px;

        @include tablet {
          width: 45%;
        }

        @include desktop {
          width: 30%;
        }

        @include widescreen {
          width: 22.5%;
        }

        &.is-more {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: relative;
          font-size: 72px;
          line-height: 1.1;
          height: 100%;

          .icon-background {
            position: absolute;
            color: $grey;
            top: 5%;
            left: 5%;
            width: 90%;
            height: 90%;
            z-index: -1;

            svg {
              width: 100%;
              height: 100%;
            }
          }

          .icon {
            font-size: 48px;
          }
        }
      }

      &.is-large {
        .carousel-item {
          @include tablet {
            width: 45%;
          }

          @include widescreen {
            width: 30%;
          }
        }
      }
    }

    footer {
      margin-top: $sc-3;
      padding-right: clamp(2.5rem, -0.3846rem + 12.8205vw, 15rem);
    }

    .section.has-background-grey & {
      .carousel-item.is-more .icon-background {
        color: $secondary;
      }
    }
  }
}
