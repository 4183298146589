nav.navbar {
  &.is-main {
    .navbar-brand,
    .navbar-buttons {
      margin: 0;
      align-items: center;
      flex-basis: 0;
      flex-grow: 1;
    }

    @include touch {
      margin-bottom: 24px;

      .navbar-brand {
        padding-right: 120px;
      }
    }

    .navbar-logo {
      height: $navbar-height;
      position: relative;

      .logo-text {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        z-index: 1;

        .logo-title {
          line-height: 0.95;
          padding-top: 0.75rem;
          margin-bottom: 10px;
          font-size: 2rem;
        }
      }

      .has-text-primary {
        position: relative;
        top: -109px;

        svg {
          transform: rotate(-2.09deg);
          width: 275px;
          height: 275px;
        }
      }
    }

    .navbar-buttons {
      display: flex;
      justify-content: flex-end;
      gap: 8px;
    }
  }
}

#toggle-mini {
  position: fixed;
  pointer-events: none;
  text-align: right;
  left: 0;
  right: 0;
  top: 8px;
  z-index: 31;

  button {
    color: $black;
    pointer-events: all;
    cursor: pointer;
    border: 0;
    width: 84px;
    height: 84px;
    border: 0;
    background: transparent;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .icon {
      &.icon-background {
        position: absolute;
        color: $primary;

        &,
        svg {
          width: 100%;
          height: 100%;
        }

        svg {
          filter: drop-shadow(0px 0px 60px #00000040);
        }
      }

      &.is-foreground {
        font-size: 24px;
        position: relative;
        z-index: 1;
      }
    }
  }

  @include desktop {
    top: 60px;
    transition: transform 0.4s;
    transform: translateY(-220px);

    &.is-active {
      transform: translateY(0);
    }
  }
}

#navbar-menu {
  .modal-content {
    @include tablet {
      width: 480px;
    }
  }
}
