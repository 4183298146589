$column-gap: clamp(0.75rem, 0.1711rem + 1.0526vw, 1.25rem);

html,
body {
  // width: 100%;
}

.container {
  width: 91%;
  max-width: 1820px;
  min-width: 300px;
  margin-left: auto;
  margin-right: auto;

  &.is-full-mobile {
    @include mobile {
      width: 100%;
    }

    @include tablet {
      & + .section {
        margin-top: 80px;
      }
    }
  }

  &.is-xs {
    max-width: 640px;
  }

  &.is-small {
    max-width: 960px;
  }

  &.is-medium {
    max-width: 1280px;
  }

  &.is-fullheight {
    height: calc(100vh - $navbar-height - 48px);
  }
}

.columns {
  &.has-boxes-fullheight .box {
    height: 100%;
  }

  &.is-scrollable {
    overflow: auto;
  }

  &.is-variable {
    margin-top: calc(-1 * var(--columnGap));
    margin-bottom: calc(-1 * var(--columnGap));

    &:not(:last-child) {
      margin-bottom: calc(1.5 * var(--columnGap));
    }

    > .column {
      padding: var(--columnGap);
    }
  }

  &:not(.is-variable):not(.is-gapless) {
    margin: calc(-1 * $column-gap);

    .column {
      padding: $column-gap;
    }
  }

  .column {
    @include mobile {
      &.is-last-mobile {
        order: 1;
      }

      &.is-first-mobile {
        order: -1;
      }
    }

    &.is-narrow {
      max-width: 100%;
    }
  }
}

.section {
  padding: $section-padding;

  &.is-medium {
    padding: $section-padding-medium;
  }

  &.is-large {
    padding: $section-padding-large;
  }

  &.is-hero {
    padding: clamp(4rem, 2.6154rem + 6.1538vi, 10rem) 0; //64-160
  }
}
