header {
  &.is-main {
    // position: relative;
    // overflow: hidden;

    .marquee:first-child {
      margin-top: 32px;
    }

    .hero {
      position: absolute;
      top: 32px;
      aspect-ratio: 1;
      z-index: -1;
      width: 200%;
      left: -50%;

      @include tablet {
        width: 100%;
        left: 0;
        top: 0;
      }

      svg {
        color: $primary;
        width: 100%;
        height: 100%;
      }
    }

    .button.is-fav {
      position: absolute;
      right: 16px;
      bottom: 16px;
    }

    .container.is-full-mobile {
      @include mobile {
        padding-top: 45px;
      }
    }
  }

  #search-container {
    .field {
      background: $white;

      @include tablet {
        padding: 16px 16px 16px 8px;
      }

      .icon {
        z-index: 5;
      }

      .input {
        background: $white;
        border: 0;
      }

      .control:last-child {
        width: $control-height;

        .button {
          height: $control-height;
        }
      }
    }
  }
}
