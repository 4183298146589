.box {
  header {
    position: relative;
  }

  main {
    padding: 16px 16px;

    .is-clamped {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      text-overflow: ellipsis;
      // hyphens: auto;
      word-wrap: break-word;
      overflow: hidden;
    }
  }

  &.is-event {
    font-weight: $weight-bold;

    header {
      .tags {
        position: absolute;
        margin-bottom: 0;
        bottom: -3px;
        left: 0;
        z-index: 1;
      }

      .button.is-fav {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;

        .icon {
          font-size: $size-7;
        }
      }
    }

    main {
      isolation: isolate;
      position: relative;
      overflow: hidden;

      p,
      span {
        position: relative;
        z-index: 2;
      }

      & > .is-overlay {
        z-index: 1;
        width: calc(100% + 60px);
        height: calc(100% + 60px);
        display: none;
        background-color: $secondary;
        color: $grey;
      }
    }

    @include desktop {
      &:hover {
        main {
          & > .is-overlay {
            display: block;

            .has-background-primary & {
              background-color: $primary;
            }
          }

          .has-pattern-1 {
            animation: pattern-a1 1s linear infinite;
          }

          .has-pattern-2,
          .has-pattern-5 {
            animation: pattern-a2 1s linear infinite;
          }

          .has-pattern-3 {
            animation: pattern-a3 1s linear infinite;
          }

          .has-pattern-4 {
            animation: pattern-a4 1s linear infinite;
          }
        }
      }
    }
  }

  &.is-news {
    main {
      background: $grey;
      display: flex;
      flex-direction: column;

      .columns {
        margin-top: auto;
      }
    }

    &:hover {
      main {
        background: $primary;
      }
    }

    &.is-large {
      @include tablet {
        display: flex;

        .image {
          height: 100%;
        }

        header,
        main {
          flex: 1 1 50%;
        }

        main {
          padding: 24px 40px;
        }
      }
    }
  }

  &.is-search {
    display: flex;
    align-items: center;
    width: 100%;
    padding: clamp(1rem, 0.8846rem + 0.5128vw, 1.5rem) 0; //16-24
    border-bottom: 1px dashed $grey-dark;
  }
}
