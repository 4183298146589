.marquee {
  position: relative;
  display: flex;
  z-index: 1;
  gap: 0.5em;
  user-select: none;
  width: 100%;
  overflow: hidden;
  padding-bottom: 0.08333333333em;
  margin-bottom: -0.08333333333em;

  .marquee-group {
    font-weight: $weight-bold;
    flex-shrink: 0;
    display: flex;
    gap: 0.5em;
    align-items: center;
    justify-content: space-around;
    min-width: 100%;
    animation: scroll 90s linear infinite;

    .icon {
      font-size: 0.75em;
      display: block;
    }
  }

  &.is-behind {
    z-index: -2;
  }
}
