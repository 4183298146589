.title {
  hyphens: auto;
  word-wrap: break-word;
  font-weight: $weight-bold;
}

.is-underlined-hover:hover {
  text-decoration: underline;
}

.is-strikethrough {
  text-decoration: line-through !important;
}

.content {
  a {
    text-decoration: underline;
  }
}

// set line-heights / letter-spacings corresponding to sizes
@for $i from 1 through length($line-heights) {
  $lh: nth($line-heights, $i);
  $ls: nth($letter-spacings, $i);

  .is-size-#{$i} {
    line-height: #{$lh};
    letter-spacing: #{$ls};
  }
}
